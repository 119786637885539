module controllers {
    export module reporting {
        interface IReportScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }
        export class reportListCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', "bsLoadingOverlayService", "$state", "reportService", '$transitions',"$location", "$timeout",];

            breadCrumbDesc: string;

            apiReportList: uiGrid.IGridApi;

            filterNames: string[] = ["CODE", "DESC", "LEVEL", "DocumentDetail"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            sortName: string;
            sortDirection: string;

            queryString: string;

            constructor(public $scope: IReportScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService, public $q: ng.IQService, public bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private reportService: interfaces.reporting.IReportService,
                public $transitions: ng.ui.core.ITransition,
                private $location: ng.ILocationService,
                private $timeout: ng.ITimeoutService,
               ) {

                this.queryString = this.$location.search().module;
                

                this.getReportList();

                
                $transitions.onSuccess({
                    to: 'auth.ReportList',
                    from: 'auth.ReportList.**'
                }, () => {
                    this.getReportList();
                });
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiReportList.grid.getColumn(name).filters[0].term || this.apiReportList.grid.getColumn(name).filters[0].term == "")
                    {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue)
                        {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiReportList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else
                        {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiReportList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList)
                {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiReportList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    var column = this.apiReportList.grid.getColumn(this.sortName);

                    if (column.sort.direction != this.sortDirection)
                    {
                        this.apiReportList.grid.sortColumn(column, this.sortDirection);
                    }
                }
            }

            gvwReportList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                multiSelect: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                rowHeight: 36,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                        name: "EDIT",
                        displayName: "",
                        enableFiltering: false,
                        cellTemplate: `
                            <div> 
                                <button type="button" ui-sref="auth.ReportList.ViewReport({ reportId: row.entity.Id })" class="btn btn-default btn-sm">
                                    <span class="fa fa-eye"></span>
                                </button>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 35
                    },{
                    name: "CODE",
                    displayName: "Report Code",
                    field: 'Code',
                    enableColumnMenu: true,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" uib-popover="{{MODEL_COL_FIELD}}" popover-enable="{{col.width <= (MODEL_COL_FIELD.length * 7.5)}}" popover-trigger="'mouseenter'" popover-append-to-body="true">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "DESC",
                    displayName: "Description",
                    field: 'LocaleDescription',
                    enableColumnMenu: true,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    width: 400,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" uib-popover="{{MODEL_COL_FIELD}}" popover-enable="{{col.width <= (MODEL_COL_FIELD.length * 7.5)}}" popover-trigger="'mouseenter'" popover-append-to-body="true">{{MODEL_COL_FIELD}}</div>`,
                },
                {
                    name: "LEVEL",
                    displayName: "Module",
                    field: 'Level',
                    enableColumnMenu: true,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "DocumentDetail",
                    displayName: "Report Detail",
                    field: "DetailLocaleDescription",
                    enableColumnMenu: true,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    width: 530,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents" uib-popover="{{MODEL_COL_FIELD}}" popover-enable="{{col.width <= (MODEL_COL_FIELD.length * 7.5)}}" popover-trigger="'mouseenter'" popover-append-to-body="true">{{MODEL_COL_FIELD}}</div>`,
                }]
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiReportList = gridApi;

                if (this.queryString){
                    this.$timeout(() => {
                        const nameColumn = this.apiReportList.grid.columns[4]; 
                        nameColumn.filters[0].term = this.queryString;
                    });

  
                }
                

                this.apiReportList.core.on.filterChanged(this.$scope, () => { this.ReportListFilterChange(gridApi) });        
            }

            ReportListFilterChange(gridApi: uiGrid.IGridApi) {
                this.getFilters();
                this.setFilters();

                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });
            }

            getReportList() {
                return this.reportService.getReportList(Enum.EnumModelType.None).query((data) => {
                    this.$scope.IsLoading = false;
                    this.$scope.ShowGrid = true;
                    this.gvwReportList.data = data;

                    
                   
                    this.getFilters();
                    this.setFilters();
                 
                },
                    (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                    }).$promise;
            }
        }

        angular.module("app").controller("reportListCtrl", controllers.reporting.reportListCtrl);
    }
}